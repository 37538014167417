/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'theme';
@import 'typography';

html, body {
  height: 100%;
}

body {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Archivo, "Helvetica Neue", sans-serif;
  margin: 0;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: mat.get-color-from-palette($default-primary-palette, darker2);
  overflow: hidden;

  .sidebar-hold {
    width: 15rem;
    background-color: mat.get-color-from-palette($default-primary-palette, darker2);
  }

  .navigation-hold {
    position: absolute;
    // height: calc(100% - 56px);
    width: 100%;
    margin-top: 56px;
    background: rgba(255, 255, 255, 0.95);
    left: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-expansion-panel-content {
  font-family: Fusion, "Helvetica Neue", sans-serif;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  font-weight: 400;

}


.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: mat.get-color-from-palette($default-primary-palette, darker2) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: mat.get-color-from-palette($default-primary-palette, main) !important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: mat.get-color-from-palette($default-primary-palette, main) !important; /*outer ring color change*/
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px;
}

.mat-form-field-infix {
  padding: 0;
  border-top: 0;
}

//change top and padding-top as per required

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: mat.get-color-from-palette($default-primary-palette, 500) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: mat.get-color-from-palette($default-primary-palette, 500);
}


/*
.mat-dialog-container{
    position: fixed !important;
    top:10rem !important;
    left:10rem !important;
    max-width: 75% !important;
    max-height: 75% !important;
}*/

.mat-snackbar-ok {
  background-color: #44b044;
  color: #ffffff;
}

.boton-estado {
  height: 40px;
  width: 220px;

  span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #555555 !important;
  }
}

.verde {
  background-color: rgb(57, 190, 45) !important;
  color: mat.get-color-from-palette($cnc-admin-accent, 50);
}

.naranja {
  background-color: #ff9100 !important;
  color: mat.get-color-from-palette($cnc-admin-accent, 50);
}

.rojo {
  background-color: rgba(255, 0, 0, 0.62) !important;
  color: mat.get-color-from-palette($cnc-admin-accent, 50);
}

.continuar, .confirmar {
  background-color: #39be2d !important;
}

.solicitud-en-curso, .en-curso {
  background-color: #ECD6C0 !important;
}

.solicitud-realizada, .realizado {
  background-color: #E6E7E6 !important;
}

.solicitud-rechazada, .rechazado {
  background-color: #C800C8 !important;

  span {
    color: #FFFFFF !important;
  }
}

.pendiente-de-validación, .nuevo-trabajo {
  background-color: #FFBE2F !important;
}

.diseño-en-revisión {
  background-color: #ff6a00 !important;
}

.diseño-validado, .validado {
  background-color: #C4DAFA !important;
}

.en-producción {
  background-color: #84B6F4 !important;

  span {
    color: #FFFFFF !important;
  }
}

.listo-para-envío {
  background-color: #4D82BC !important;

  span {
    color: #FFFFFF !important;
  }
}

.en-tránsito {
  background-color: #646464 !important;

  span {
    color: #FFFFFF !important;
  }
}

.entregado {
  background-color: #BAD800 !important;

  span {
    color: #FFFFFF !important;
  }
}

.cargando, .diseño-validado {
  background-color: rgb(255, 145, 0) !important;
}

.item:has(.mat-form-field-invalid) {
  border: 3px solid rgba(255, 0, 0, 0.76);
  background-color: #ffeeee;
  -webkit-animation: blinker 2s linear infinite; /* Safari 4+ */
  -moz-animation: blinker 2s linear infinite; /* Fx 5+ */
  -o-animation: blinker 2s linear infinite; /* Opera 12+ */
  animation: blinker 2s linear infinite; /* IE 10+, Fx 29+ */
}

@keyframes blinker {
  50% {
    border-color: #f5f5f5;
  }
}

.cargando_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.37);
  font-size: 2rem;
  z-index: 9999;
  top: 0;
  left: 0;

  > div {
    background: mat.get-color-from-palette($cnc-admin-primary, 100);
    opacity: 0.8;
    width: 700px;
    height: 60px;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
}


.bloqueada, .bloqueado, .pagado {
  //background-color: rgba(255, 0, 0, 0.41) !important;
  position: relative;
}
