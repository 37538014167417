@use '@angular/material' as mat;

@font-face {
  font-family: 'Fusion';
  src: url('/assets/fonts/fusion.woff2');
}

$cnc-typography: mat.define-typography-config(
        $font-family: 'Fusion, "Helvetica Neue", sans-serif'
);

$cnc-typography-improve-reading: mat.define-typography-config(
        $font-family: 'Roboto, "Helvetica Neue", "HelveticaNeue", Helvetica, sans-serif'
);

@include mat.all-component-typographies($cnc-typography-improve-reading);
@include mat.typography-hierarchy($cnc-typography);
@include mat.toolbar-typography($cnc-typography);
@include mat.button-typography($cnc-typography);
@include mat.list-typography($cnc-typography);
@include mat.expansion-typography($cnc-typography);

.mat-header-row {
  font-family: mat.font-family($cnc-typography);
}

.widget-wrapper {
  font-family: mat.font-family($cnc-typography-improve-reading);
}


/*
Reference for future use:

@import '@angular/material/theming';
// Create a config with the default typography levels.
$config: mat-typography-config();

mat-font-size($config, $level) - Gets the font-size, based on the provided config and level.
mat-font-family($config) - Gets the font-family, based on the provided config.
mat-line-height($config, $level) - Gets the line-height, based on the provided config and level.
mat-font-weight($config, $level) - Gets the font-weight, based on the provided config and level.
mat-typography-level-to-styles($config, $level) - Mixin that takes in a configuration object and a typography level,
and outputs a short-hand CSS font declaration.

More info: https://material.angular.io/guide/typography
*/
